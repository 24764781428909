import React, { useState, useEffect } from "react";
import {
  Button,
  Label,
  Input,
  Textarea,
  Flex,
  Divider,
  Heading,
} from "@theme-ui/components";
import ImageUpload from "../../../app/DSL/components/elements/ImageUpload";
import styled from "@emotion/styled";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import { Ring } from "react-awesome-spinners";
import Select from "react-select";
import { customStyles } from "../../../app/forms/Form";
import Brochures from "./Brochures";
import { categories } from "../../../app/context/catalogue/categories";
import { types } from "../../../app/context/catalogue/types";

export default function ProductForm({
  archive,
  submit,
  buttonText,
  product,
  create,
  goBack,
}) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();
  const imgCropper = React.createRef();

  useEffect(() => {
    if (!values) {
      if (product) {
        if (product.brochures) {
          setValues(product);
        } else {
          setValues({
            ...product,
            brochures: [
              {
                title: "",
                pdfURL: "",
                type: "link",
              },
            ],
          });
        }
      } else {
        setValues({
          photoURL: "",
          videoURL: "",
          videoURLBrightCove: "",
          title: "",
          type: "",
          category: "",
          description: "",
          tip: "",
          brochures: [
            {
              title: "",
              pdfURL: "",
              type: "link",
            },
          ],
        });
      }
    }
  }, [values, product]);

  const uploadFile = async (type, cropper) => {
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    setLoading(type);

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully

        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg

        const url = response.secure_url;
        setValues((prev) => ({ ...prev, photoURL: url }));
        setLoading(false);
        return url;
        // addImage(url);
      }
    };

    fd.append("upload_preset", process.env.REACT_APP_PRESET);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append(
      "file",
      cropper?.current?.cropper
        ?.getCroppedCanvas({ width: 600, height: 600 })
        .toDataURL()
    );
    xhr.send(fd);
  };

  const addBrochure = (brochure) => {
    const brochuresArray = values.brochures;
    brochuresArray.push(brochure);
    setValues({
      ...values,
      brochures: brochuresArray,
    });
  };

  console.log(values);

  return values ? (
    <div>
      <Grid>
        <div>
          <Grid>
            <InputWrap>
              <Label>Title</Label>
              <Input
                value={values.title}
                onChange={(e) => {
                  e.persist();
                  setValues((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              ></Input>
            </InputWrap>
          </Grid>
          <Grid>
            <InputWrap style={{ margin: "1em 0" }}>
              <Label>Category</Label>
              <Select
                styles={customStyles}
                isClearable
                isSearchable
                options={categories}
                value={categories.find(
                  (c) =>
                    c.value.toLowerCase() === values?.category?.toLowerCase()
                )}
                placeholder="Select a category"
                onChange={(option) => {
                  setValues((prev) => ({
                    ...prev,
                    category: option?.value ? option.value : null,
                  }));
                }}
              />
            </InputWrap>
          </Grid>
          <Grid>
            <InputWrap style={{ margin: "1em 0" }}>
              <Label>Type</Label>
              <Select
                styles={customStyles}
                isClearable
                isSearchable
                options={types}
                value={types.find(
                  (t) => t.value.toLowerCase() === values?.type?.toLowerCase()
                )}
                placeholder="Select a type"
                onChange={(option) => {
                  setValues((prev) => ({
                    ...prev,
                    type: option?.value ? option.value : null,
                  }));
                }}
              />
            </InputWrap>
          </Grid>
          {/* TODO: add brand field */}
          {/*  <Grid>
            <InputWrap style={{ margin: "1em 0" }}>
              <Label>Brand</Label>
              <Select
                styles={customStyles}
                isClearable
                isSearchable
                options={brands[values.category]}
                value={brands[values.category]?.find(
                  (t) => t.value.toLowerCase() === values.brand?.toLowerCase()
                )}
                placeholder="Select a brand"
                onChange={(option) => {
                  setValues((prev) => ({
                    ...prev,
                    brand: option.value,
                  }));
                }}
              />
            </InputWrap>
          </Grid> */}
          <Grid>
            <InputWrap>
              <Label>Product Description</Label>
              <Textarea
                value={values.description}
                onChange={(e) => {
                  e.persist();
                  setValues((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
                rows={6}
              ></Textarea>
            </InputWrap>
          </Grid>
          <Grid>
            <InputWrap>
              <Label>Product Focus</Label>
              <Textarea
                value={values.tip}
                onChange={(e) => {
                  e.persist();
                  setValues((prev) => ({
                    ...prev,
                    tip: e.target.value,
                  }));
                }}
                rows={3}
              ></Textarea>
            </InputWrap>
          </Grid>
          <Grid>
            <InputWrap>
              <Label>Product Video URL</Label>
              <Input
                value={values.videoURL}
                onChange={(e) => {
                  e.persist();
                  setValues((prev) => ({
                    ...prev,
                    videoURL: e.target.value,
                  }));
                }}
              ></Input>
            </InputWrap>
          </Grid>
          <Grid>
            <InputWrap>
              <Label>Product Video URL (BrightCove)</Label>
              <Input
                value={values.videoURLBrightCove}
                onChange={(e) => {
                  e.persist();
                  setValues((prev) => ({
                    ...prev,
                    videoURLBrightCove: e.target.value,
                  }));
                }}
              ></Input>
            </InputWrap>
          </Grid>
        </div>
        <div>
          <InputWrap>
            <Label>Product Image</Label>
            <ImageUpload
              image={values.photoURL}
              cropper={imgCropper}
              dimensions={{ width: "300px", height: "300px" }}
              upload={() => uploadFile("product", imgCropper)}
              uploading={loading === "product"}
              name="product"
            />
          </InputWrap>
        </div>
      </Grid>
      <Divider />
      <DocsContainer>
        <Heading as="h3">Documentation</Heading>
        <Brochures values={values} setValues={setValues} />
        <Button
          onClick={() => addBrochure({ title: "", pdfURL: "", type: "link" })}
        >
          Add Document
        </Button>
      </DocsContainer>
      <Divider />
      <Flex style={{ justifyContent: "space-between" }}>
        {create ? (
          <Button onClick={() => goBack()} variant="outline">
            Cancel
          </Button>
        ) : (
          <ArchiveButton variant="outline" onClick={archive}>
            Archive Product
          </ArchiveButton>
        )}

        <Button
          onClick={() => submit(values)}
          variant={values.photoURL ? "primary" : "disabled"}
        >
          {buttonText}
        </Button>
      </Flex>
    </div>
  ) : (
    <div>
      <Ring color="blue" />
    </div>
  );
}

const ArchiveButton = styled(Button)`
  border-color: #b83048;
  color: #b83048 !important;
  &:hover {
    background-color: #b83048 !important;
    color: #fff !important;
    border-color: #b83048 !important;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1em;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const DocsContainer = styled.div`
  margin-top: 20px;
  h3 {
    margin-bottom: 20px !important;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
