export const categories = [
  {
    label: "Cement",
    value: "Cement",
  },
  {
    label: "Extremities",
    value: "Extremities",
  },
  {
    label: "Hips",
    value: "Hips",
  },
  {
    label: "Knees",
    value: "Knees",
  },
  {
    label: "Spine",
    value: "Spine",
  },
  {
    label: "Sportsmed",
    value: "Sportsmed",
  },
  {
    label: "Surgical",
    value: "Surgical",
  },
  {
    label: "Trauma",
    value: "Trauma",
  },
];
