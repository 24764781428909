import React from "react";
import { CSVLink } from "react-csv";
import { getToday } from "../../app/DSL/components/timeFunctions";
// import SimpleLoader from '../../../app/layout/SimpleLoader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//TODO: duplicated
export default function DownloadReport({ data, headers, fileName }) {
  const today = getToday();
  return (
    <CSVLink
      data={data}
      headers={headers}
      className="download-btn"
      filename={`zimmer-biomet-report-${fileName}-${today}.csv`}
    >
      <FontAwesomeIcon icon={["fas", "download"]} />{" "}
      <span>Download Report</span>
    </CSVLink>
  );
}
