import React from "react";
import styled from "@emotion/styled";
import { Card } from "@theme-ui/components";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import MostViewedProducts from "./MostViewedProducts";
import LeastViewedProducts from "./LeastViewedProducts";

export default function AdminDashboard() {
  return (
    <div>
      <PageHeaderContainer>Admin Dashboard</PageHeaderContainer>
      <TopGrid>
        <Card>
          <BlockHeaderContainer title="Most Viewed Products" />
          <MostViewedProducts />
        </Card>
        <Card>
          <BlockHeaderContainer title="Least Viewed Products" />
          <LeastViewedProducts />
        </Card>
      </TopGrid>
    </div>
  );
}

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
