import React from "react";
import { useToasts } from "react-toast-notifications";
import EventForm from "./EventForm";
import { useEvents } from "../../../app/context/events";

export default function CreateEvent({ goBack, setView }) {
  const { createEvent } = useEvents();
  const { addToast } = useToasts();

  const submit = (values) => {
    createEvent({
      ...values,
      dateCreated: new Date(),
    }).then(() => {
      addToast("Event Created", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      // setView("view");
    });
  };

  return <EventForm buttonText="Create Event" submit={submit} />;
}
