import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";

const ProductsContext = createContext({});

export const ProductsProvider = ({ children }) => {
  const firebase = useFirebase();
  const [products, setProducts] = useState([]);
  const { user } = useAuthContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .catalogue()
          .onSnapshot((snap) =>
            setProducts(
              transformCollectionSnap(snap).filter((p) => !p.isArchived)
            )
          )
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  const createProduct = (values) => {
    const brochures = values.brochures
      ? values.brochures.filter((b) => b?.pdfURL.length > 0)
      : [];
    return firebase
      .catalogue()
      .add({ ...values, createdAt: new Date(), brochures });
  };

  const updateProduct = (values) => {
    const brochures = values.brochures
      ? values.brochures.filter((b) => b?.pdfURL.length > 0)
      : [];
    return firebase
      .catalogue(values.id)
      .set({ ...values, updatedAt: new Date(), brochures }, { merge: true });
  };

  const viewProduct = (values) => {
    return firebase.catalogue(values.id).update({
      ...values,
      viewCount: firebase.firestore.FieldValue.increment(1),
    });
  };

  return (
    <ProductsContext.Provider
      value={{ products, createProduct, updateProduct, viewProduct }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const ProductsConsumer = ProductsContext.Consumer;

export const useProducts = () => useContext(ProductsContext);
