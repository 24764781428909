import React from "react";
import styled from "@emotion/styled";
import { regions } from "./data";

//TODO: duplicated

export default function EnquiryDetails({ item }) {
  return (
    <DetailsBlock>
      <div className="twoCol">
        <p>
          <span>Event Name:</span> {item.event}
        </p>
        <p>
          <span>Sales Rep:</span> {item.salesRep}
        </p>
      </div>
      <div className="twoCol">
        <p>
          <span>First Name:</span> {item.firstName}
        </p>
        <p>
          <span>Last Name:</span> {item.lastName}
        </p>
      </div>
      <div className="twoCol">
        <p>
          <span>Email:</span> {item.email}
        </p>
        <p>
          <span>Phone:</span> {item.phone}
        </p>
      </div>
      <div className="twoCol">
        <div>
          <p>
            <span>Location:</span>{" "}
            {regions.find((r) => r.value === item.region)?.label}
          </p>
        </div>
        <div>
          <p>
            <span>Place of work:</span> {item.workPlace}
          </p>
          <p>
            <span>Hospital/Practice name:</span> {item.workPlaceName}
          </p>
        </div>
      </div>
      <div className="twoCol">
        <div>
          <span>Enquiry Categories:</span>
          {!item.informationRequired ? (
            "-"
          ) : (
            <ul>
              {item.informationRequired?.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          )}
        </div>
        {/* <div>
          <span>Enquiry Brands:</span>
          {!item.brandRequired ? (
            "-"
          ) : (
            <ul>
              {item.brandRequired?.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          )}
        </div> */}
        <div>
          <span>Enquiry Products:</span>
          {!item.productRequired ? (
            "-"
          ) : (
            <ul>
              {item.productRequired?.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="twoCol">
        <div>
          <span>Enquiry Information:</span>
          {!item.documentsRequired ? (
            "-"
          ) : (
            <ul>
              {item.documentsRequired?.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          )}
        </div>
        <div>
          <span>Medical Education interest:</span>
          {!item.medicalEducation ? (
            "-"
          ) : (
            <ul>
              {item.medicalEducation?.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <p>
        <span>Comments:</span> {item.comments}
      </p>
    </DetailsBlock>
  );
}

const DetailsBlock = styled.div`
  font-size: 20px;
  div.twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    div {
      margin: 1em 0;
    }
  }
  span {
    font-weight: 700;
  }
`;
