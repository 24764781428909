import React from "react";
import { useEvents } from "../../../app/context/events";
import { Card, Button, Flex } from "@theme-ui/components";
import styled from "@emotion/styled";
import { useFirebase } from "../../../app/context/Firebase";
import { useToasts } from "react-toast-notifications";
import format from "date-fns/format";

export default function EventsList() {
  const { events } = useEvents();

  const firebase = useFirebase();
  const { addToast } = useToasts();

  const archiveEvent = (id) => {
    firebase.archiveEvent(id);
    addToast("Event archived", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 1500,
    });
  };

  return (
    <div>
      {events &&
        events
          .filter((u) => !u.isArchived)
          .map((event, index) => {
            return (
              <EventCard key={index}>
                <Flex style={{ justifyContent: "space-between" }}>
                  <h4>{event.title}</h4>
                  <p>
                    {event?.dateCreated &&
                      format(event.dateCreated.toDate(), "YYY/MM/dd")}
                  </p>
                  <Button onClick={() => archiveEvent(event.id)}>X</Button>
                </Flex>
              </EventCard>
            );
          })}
    </div>
  );
}

const EventCard = styled(Card)`
  button {
    padding: 0px 10px;
  }
`;
