import React, { useState, useMemo } from "react";
import { Card, Label, Input, Image } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import styled from "@emotion/styled";
import { useProducts } from "../../../app/context/catalogue";
import ProductCard from "./ProductCard";
import {
  InputWrap,
  IconLeft,
} from "../../../app/DSL/components/form/StyledInput";
import { categories } from "../../../app/context/catalogue/categories";
import { types } from "../../../app/context/catalogue/types";
import { customStyles } from "../../../app/forms/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
//import { brands } from "../../../app/context/catalogue/brands";

export default function ViewCatalogue({ setView, setSelectedProduct }) {
  const { products } = useProducts();

  const [category, setCategory] = useState();
  const [type, setType] = useState();
  //const [brand, setBrand] = useState();
  const [search, setSearch] = useState();

  const catalogue = useMemo(
    () =>
      products
        ? products
            .filter((p) => !p.isArchived)
            .filter((p) =>
              category && category.value
                ? p.category.toLowerCase() === category.value.toLowerCase()
                : true
            )
            .filter((p) =>
              type && type.value
                ? p.type.toLowerCase() === type.value.toLowerCase()
                : true
            )
            /* .filter((p) =>
              brand && brand.value
                ? p.brand?.toLowerCase() === brand.value?.toLowerCase()
                : true
            ) */
            .filter((p) =>
              search
                ? p.title.toLowerCase().includes(search.toLowerCase())
                : true
            )
            .sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            )
        : [],
    [category, search, products, type /* brand */]
  );

  return (
    <Card>
      <BlockHeaderContainer
        title={`Showing ${catalogue?.length} Product${
          catalogue?.length === 1 ? "" : "s"
        }`}
        actionType="button"
        action={() => setView("create")}
        buttonText="Create Product"
      />

      <FiltersBox>
        <InputWrap className="wrap" icon="left" style={{ margin: "1em 0" }}>
          <Label>Search</Label>
          <IconLeft>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </IconLeft>
          <Input
            placeholder="Search products"
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputWrap>
        <InputWrap className="wrap" style={{ margin: "1em 0" }}>
          <Label>Category</Label>
          <Select
            styles={customStyles}
            isClearable
            isSearchable
            options={categories}
            value={category}
            placeholder="Select a category"
            onChange={(option) => setCategory(option)}
          />
        </InputWrap>
        <InputWrap className="wrap" style={{ margin: "1em 0" }}>
          <Label>Type</Label>
          <Select
            styles={customStyles}
            isClearable
            isSearchable
            options={types}
            value={type}
            placeholder="Select a type"
            onChange={(option) => setType(option)}
          />
        </InputWrap>
        {/* TODO: add brand field */}
        {/* <InputWrap className="wrap" style={{ margin: "1em 0" }}>
          <Label>Brand</Label>
          <Select
            styles={customStyles}
            isClearable
            isSearchable
            options={brands[category?.value]}
            value={brand}
            placeholder="Select a brand"
            onChange={(option) => {
              setBrand(option);
            }}
          />
        </InputWrap> */}
      </FiltersBox>

      {catalogue?.length > 0 ? (
        <Grid>
          {catalogue?.map((p, idx) => (
            <Product
              setSelectedProduct={setSelectedProduct}
              setView={setView}
              product={p}
              key={idx}
              style={{ padding: "1em" }}
            />
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <Image src="/assets/404.svg" width="30%" />
          <h3>No products found. Try another search</h3>
        </div>
      )}
    </Card>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  @media (max-width: 1124px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
  max-height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em;
`;

const FiltersBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  background-color: #f4f4f6;
  padding: 0px 15px;
  border-radius: 4px;
  @media (max-width: 1124px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    div.wrap {
      margin: 0.5em 0px 0em !important;
    }
  }
`;

const Product = styled(ProductCard)``;
