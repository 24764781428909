import React, { useState } from "react";
import { Button, Label, Input, Flex } from "@theme-ui/components";
import styled from "@emotion/styled";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";

export default function EventForm({ cancel, submit, buttonText, product }) {
  //const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    title: "",
  });

  return (
    <Grid>
      <p>Create new events that will be listed on the enquiry form.</p>
      <InputWrap>
        <Label>Title</Label>
        <Input
          value={values.title ? values.title : ""}
          onChange={(e) => {
            e.persist();
            setValues((prev) => ({
              ...prev,
              title: e.target.value,
            }));
          }}
        ></Input>
      </InputWrap>

      <Flex style={{ justifyContent: "space-between" }}>
        <Button
          onClick={() => submit(values)}
          variant={values.title ? "primary" : "disabled"}
        >
          {buttonText}
        </Button>
      </Flex>
    </Grid>
  );
}

const Grid = styled.div`
  /* display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1em; */
`;
