import React from "react";
import styled from "styled-components";

//TODO: match HQ

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Box>
            <h1>Something went wrong.</h1>
            <h3>
              Please try login in again at:{" "}
              <a href={process.env.REACT_APP_HQ_LINK}>
                {process.env.REACT_APP_HQ_LINK}
              </a>
            </h3>
          </Box>
        </Container>
      );
    }
    return this.props.children;
  }
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 350px;
  justify-content: center;
  align-content: center;
  height: 100vh;
  background-image: url(${process.env.REACT_APP_BG_URL});
  background-size: ${process.env.REACT_APP_BG_SIZE};
  background-repeat: ${process.env.REACT_APP_BG_REPEAT};
  background-color: #e9e9ed;
`;

const Box = styled.div`
  padding: 2em;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
`;

export default ErrorBoundary;
