import React from "react";
import styled from "@emotion/styled";
import { primary } from "../../theme";
import { Heading } from "@theme-ui/components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMenu } from "../../../context/menu";
import release from "../../../config/release.json";

//TODO: extract nav items to file and loop through

export default function AdminSideBar() {
  const { showAdminBar } = useMenu();
  return (
    <Container show={showAdminBar} className="sidenav">
      <Content show={showAdminBar}>
        <Header>
          <Heading as="h2" color="white">
            Admin Area
          </Heading>
        </Header>
        <TopOptions>
          <IconBox>
            <BoxLink to="/admin-area/dashboard">
              <FontAwesomeIcon icon={["fal", "th"]} size="lg" />
              <p>Dashboard</p>
            </BoxLink>
          </IconBox>
          <IconBox>
            <BoxLink to="/admin-area/user-activity">
              <FontAwesomeIcon icon={["fal", "user-friends"]} size="lg" />
              <p>User Activity</p>
            </BoxLink>
          </IconBox>
          <IconBox>
            <BoxLink to="/admin-area/catalogue">
              <FontAwesomeIcon icon={["fal", "book-open"]} size="lg" />
              <p>Catalogue</p>
            </BoxLink>
          </IconBox>
          <IconBox>
            <BoxLink to="/admin-area/enquiries">
              <FontAwesomeIcon icon={["fal", "user-md-chat"]} size="lg" />
              <p>Enquiries</p>
            </BoxLink>
          </IconBox>
          <IconBox>
            <BoxLink to="/admin-area/events">
              <FontAwesomeIcon icon={["fal", "calendar"]} size="lg" />
              <p>Events</p>
            </BoxLink>
          </IconBox>
        </TopOptions>
        <BotOptions>
          <small>Version {release.versionClient}</small>
        </BotOptions>
      </Content>
    </Container>
  );
}
const Container = styled.div`
  width: ${(props) => (props.show ? "250px" : "0px")};
  margin-left: -5em;
  margin-bottom: -2em;
  transition: width ease-out 0.3s 0.1s;
  margin-right: 5em;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colors[primary].shade["600"]};
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-rows: 75px 1fr 1fr;
  position: fixed;
  height: 100vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${(props) => (props.show ? "250px" : "0px")};
  transition: width ease-out 0.3s 0.1s;
`;

const Header = styled.div`
  margin: 0 2em;
  align-self: center;
`;

const TopOptions = styled.div`
  display: grid;
  align-content: start;
  border-top: 1px solid ${(props) => props.theme.colors[primary].shade["800"]};
`;

const BotOptions = styled.div`
  display: grid;
  align-items: end;
  justify-items: center;
  align-self: end;
  margin-bottom: 1em;
  small {
    color: ${(props) => props.theme.colors[primary].font["700"]};
  }
`;

const IconBox = styled.div`
  a[aria-current="page"] {
    background: ${(props) => props.theme.colors[primary].shade["800"]};
  }
  cursor: pointer;
  height: 75px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors[primary].shade["800"]};
`;

const BoxLink = styled(NavLink)`
  width: calc(100% - 4em);
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
  align-items: center;
  padding: 0 2em;
  * {
    color: #fff;
  }
  svg {
  }
  h4 {
    margin: 0;
  }
`;
