import React from "react";
import styled from "@emotion/styled";
import { useUsers } from "../../../app/context/users";
import { useMemo } from "react";
import { Card } from "@theme-ui/components";
import format from "date-fns/format";

export default function InactiveUsers() {
  const { users } = useUsers();

  const recentUsers = useMemo(
    () =>
      users
        ? users.filter((u) => !u.isArchived).filter((u) => !u.lastLoginBrochure)
        : // .sort((a, b) =>
          //   a.lastLoginBrochure > b.lastLoginBrochure ? 1 : -1
          // )
          // .sort((a, b) => b.lastLoginBrochure - a.lastLoginBrochure)
          // .slice(0, 5)
          [],
    [users]
  );

  console.log(recentUsers);

  return (
    <div>
      {recentUsers ? (
        recentUsers.map((u, idx) => (
          <ProductItem
            key={idx}
            style={{ textAlign: "center", height: "100%" }}
          >
            <div className="productFooter">
              <>{u.displayName}</>
              {u.lastLoginBrochure && (
                <span>
                  Last login:
                  {format(u.lastLoginBrochure.toDate(), "YYY/MM/dd")}
                </span>
              )}
            </div>
          </ProductItem>
        ))
      ) : (
        <p>All users have logged in</p>
      )}
    </div>
  );
}

const ProductItem = styled(Card)`
  text-align: left !important;
  margin-bottom: 10px;
  padding: 1em !important;
  border: 1px solid #e9e9ed;
  border-bottom: 2px solid #0081c9;
  position: relative;
  .productFooter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    span {
      font-size: 12px;
      color: #999;
      margin-top: 2px;
      text-align: right;
    }
  }
  &:hover {
    cursor: pointer;
  }
`;
