import React from "react";
import { UsersProvider } from "../users";
import { HospitalsProvider } from "../hospitals";
import { ProductsProvider } from "../catalogue";
import { EnquiriesProvider } from "../enquiries";
import { EventsProvider } from "../events";
import { MenuProvider } from "../menu";
import { SlidersProvider } from "../sliders";

export const DataProvider = ({ children }) => {
  return (
    <UsersProvider>
      <HospitalsProvider>
        <ProductsProvider>
          <EnquiriesProvider>
            <MenuProvider>
              <EventsProvider>
                <SlidersProvider>{children}</SlidersProvider>
              </EventsProvider>
            </MenuProvider>
          </EnquiriesProvider>
        </ProductsProvider>
      </HospitalsProvider>
    </UsersProvider>
  );
};
