export const types = [
  {
    label: "Primary",
    value: "Primary",
  },
  {
    label: "Revision",
    value: "Revision",
  },
];
