import React from "react";
import styled from "@emotion/styled";
import { useToasts } from "react-toast-notifications";
import ProductForm from "./ProductForm";
import { Card, Image } from "@theme-ui/components";
import { useProducts } from "../../../app/context/catalogue";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";

export default function CreateProduct({ setView }) {
  const { createProduct } = useProducts();
  const { addToast } = useToasts();

  const submit = (values) => {
    createProduct(values).then(() => {
      addToast("Product Created", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      setView("view");
    });
  };
  const goBack = () => {
    setView("view");
  };

  return (
    <Container>
      <CardContainer>
        <BlockHeaderContainer
          title="Create Product"
          actionType="button"
          action={() => setView("view")}
          buttonText="Back to catalogue"
        />
        <ProductForm
          buttonText="Create Product"
          submit={submit}
          goBack={goBack}
          create
        />
      </CardContainer>
      <Card>
        <div className="hide-on-desktop" style={{ textAlign: "center" }}>
          <Image
            src="/assets/desktop.svg"
            style={{ width: "45%", margin: "auto" }}
          />
          <h4> Please go to the desktop version to create products</h4>
        </div>
      </Card>
    </Container>
  );
}

const CardContainer = styled(Card)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const Container = styled.div`
  .hide-on-desktop {
    display: none;
  }
  @media (max-width: 800px) {
    .hide-on-desktop {
      display: block;
    }
  }
`;
