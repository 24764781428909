import React from "react";
import Table from "../../../app/DSL/components/elements/Table";
import { useEnquiries } from "../../../app/context/enquiries";
import { useMemo } from "react";
import {
  RoundButton,
  ActionsTableContainer,
} from "../../../app/DSL/components/elements/styled";
//import { useFirebase } from "../../../app/context/Firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
//import { useToasts } from "react-toast-notifications";
//import ConfirmationModal from "../../../app/DSL/components/elements/ConfirmationModal";
// import { useUserContext } from "../../../app/context/auth";
import DownloadReport from "./DownloadReport";
import styled from "@emotion/styled";
import { format } from "date-fns";

//TODO: Clean up commented out sections. Do enquiries need to be archived?
//TODO: Duplicated

export default function EnquiriesTable({ viewEnquiry }) {
  /*   const [modalOpen, setModalOpen] = useState(false);
  const [confirmItem, setConfirmItem] = useState({});

  const { addToast } = useToasts();

  const firebase = useFirebase(); */
  const { enquiries } = useEnquiries();

  /* const archiveEnquiry = (id) => {
    firebase.archiveEnquiry(id);
    setModalOpen(false);
    addToast("Enquiry archived", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 1500,
    });
  }; */

  const enquiryList = useMemo(() => enquiries.filter((u) => !u.isArchived), [
    enquiries,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "Date ",
        id: "dateCreated",
        accessor: (d) => format(d?.dateCreated.toDate(), "YYY/MM/dd"),
        filter: "fuzzyText",
      },
      {
        Header: "Name",
        id: "name",
        accessor: (d) => `${d.firstName} ${d.lastName}`,
        filter: "fuzzyText",
      },
      {
        Header: "Phone Number",
        id: "phone",
        accessor: (d) => d.phone,
        filter: "fuzzyText",
      },
      {
        Header: "Email Address",
        id: "email",
        accessor: (d) => d.email,
        filter: "fuzzyText",
      },
      {
        Header: "Enquiry",
        id: "informationRequired",
        accessor: (d) => d.informationRequired,
        filter: "fuzzyText",
      },

      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              {/* <RoundButton
                data-tip='Archive enquiry'
                data-for='actionName'
                variant='secondary'
                onClick={() => {
                  setModalOpen(true);
                  setConfirmItem(cell.row.original);
                }}
              >
                <FontAwesomeIcon icon={["fas", "trash"]} />
              </RoundButton> */}

              <RoundButton
                data-tip="View enquiry"
                data-for="actionName"
                variant="secondary"
                onClick={() => viewEnquiry(cell.row.original)}
              >
                <FontAwesomeIcon icon={["fas", "eye"]} />
              </RoundButton>

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [viewEnquiry]
  );

  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "Email" },
    { label: "Place Of Work", key: "workPlace" },
    { label: "Location", key: "location" },
    { label: "Comments", key: "comments" },
    { label: "Enquiry Categories", key: "informationRequired" },
    { label: "Enquiry Product", key: "productRequired" },
    { label: "Enquiry Information", key: "documentsRequired" },
    { label: "Created By", key: "createdBy" },
    { label: "Date Created", key: "dateCreated" },
  ];

  return (
    <span>
      <ActionsTableContainer>
        <Table
          emptyMsg="No enquiries"
          columns={columns}
          data={enquiryList}
          defaultRows={5}
          emptyIcon="user-md"
        />
      </ActionsTableContainer>
      {/* <ConfirmationModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        name={confirmItem.displayName}
        id={confirmItem.id}
        action={archiveEnquiry}
        actionType="archive"
        type="enquiry"
      /> */}
      <DownloadSection>
        <DownloadReport
          data={enquiryList}
          headers={headers}
          // loading={!reportsLoaded}
          className="download-btn"
          fileName="alltime"
        />
      </DownloadSection>
    </span>
  );
}

const DownloadSection = styled.div`
  margin-top: 20px;
  text-align: center;
  a {
    background-color: #0079bd;
    color: #fff;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.7em - 1px);
    font-weight: 700;
    border-radius: 4px;
    &:hover {
      background-color: #00659e;
    }
  }
`;
