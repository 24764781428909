import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";

const EventsContext = createContext({});

export const EventsProvider = ({ children }) => {
  const firebase = useFirebase();
  const [events, setEvents] = useState();
  const { user } = useAuthContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .events()
          .onSnapshot((snap) =>
            setEvents(
              transformCollectionSnap(snap).filter((p) => !p.isArchived)
            )
          )
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  const createEvent = (values) => {
    return firebase.events().add(values);
  };

  return (
    <EventsContext.Provider value={{ events, createEvent }}>
      {children}
    </EventsContext.Provider>
  );
};

export const EventsConsumer = EventsContext.Consumer;

export const useEvents = () => useContext(EventsContext);
