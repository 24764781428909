import React, { useState } from "react";
import { useFirebase } from "../../app/context/Firebase";
import { useToasts } from "react-toast-notifications";
import EnquiryForm from "./EnquiryForm";
import { useUserContext } from "../../app/context/auth";

export default function CreateEnquiry({ goBack }) {
  const firebase = useFirebase();

  const user = useUserContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { addToast } = useToasts();

  const handleSubmit = async (e, formValues, reset, createAnother) => {
    setLoading(true);
    e.preventDefault();
    formValues.displayName = `${formValues.firstName} ${formValues.lastName}`;
    delete formValues.formValid;
    debugger;
    //TODO: move to context
    return firebase
      .enquiries()
      .doc()
      .set({
        ...formValues,
        createdBy: user.id,
        dateCreated: new Date(),
      })

      .then(() => {
        addToast("Added Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
          transitionDuration: 10,
          transitionState: "entered",
        });
        setLoading(false);
        if (createAnother) reset();
        else goBack();
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
        console.log(error);
      });
  };

  return (
    <EnquiryForm
      initialValues={{
        event: "SAOA 2021",
        workPlace: "Hospital",
      }}
      loading={loading}
      error={error}
      handleSubmit={handleSubmit}
      submitText="Submit Enquiry"
      create={true}
    />
  );
}
