import React from "react";
import { Card, Heading, AspectRatio } from "@theme-ui/components";
import { ImageBlock } from "../../../app/DSL/components/elements/styled";
import styled from "@emotion/styled";

export default function ProductCard({ product, setView, setSelectedProduct }) {
  return (
    <ProductContainer
      style={{ textAlign: "center", height: "100%" }}
      variant="hoverable"
      onClick={() => {
        setSelectedProduct(product);
        setView("edit");
      }}
    >
      <AspectRatio ratio={1 / 1}>
        <ImageBlock
          background="white"
          size="full"
          style={{ marginBottom: "1em" }}
          url={
            product && product.photoURL
              ? product.photoURL
              : "/user-circle-solid.svg"
          }
        >
          <div className="img"></div>
        </ImageBlock>
      </AspectRatio>
      <ProductHeading as="h3">{product.title}</ProductHeading>
    </ProductContainer>
  );
}

const ProductHeading = styled(Heading)`
  min-height: 46px;
`;

/* const SubHeading = styled(Heading)`
  color: ${(props) => props.theme.colors[primary].shade["500"]};
  font-weight: 400;
  font-size: 1em;
`; */

const ProductContainer = styled(Card)`
  position: relative;
  h3 {
    margin-top: 10px;
  }
`;
