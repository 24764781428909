import React, { useState } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import CreateProduct from "./CreateProduct";
import EditProduct from "./EditProduct";
import ViewCatalogue from "./ViewCatalogue";

export default function AdminCatalogue() {
  const [view, setView] = useState("view");
  const [selectedProduct, setSelectedProduct] = useState();
  return (
    <div>
      <PageHeaderContainer>Manage Catalogue</PageHeaderContainer>
      {
        {
          view: (
            <ViewCatalogue
              setView={setView}
              setSelectedProduct={setSelectedProduct}
            />
          ),
          create: <CreateProduct setView={setView} />,
          edit: (
            <EditProduct
              setView={setView}
              selectedProduct={selectedProduct}
            />
          ),
        }[view]
      }
    </div>
  );
}
