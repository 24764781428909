import React from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { Card } from "@theme-ui/components";
import EventsList from "./EventsList";
import CreateEvent from "./CreateEvent";
import styled from "@emotion/styled";

export default function AdminEvents() {
  return (
    <div>
      <PageHeaderContainer>Manage Events</PageHeaderContainer>
      <TopGrid>
        <Card>
          <BlockHeaderContainer title="Add Event" />
          <CreateEvent />
        </Card>
        <Card>
          <BlockHeaderContainer title="Current Events" />
          <EventsList />
        </Card>
      </TopGrid>
    </div>
  );
}

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
