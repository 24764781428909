import React from "react";
import Table from "../../app/DSL/components/elements/Table";
import { useEnquiries } from "../../app/context/enquiries";
import { useMemo } from "react";
import { ActionsTableContainer } from "../../app/DSL/components/elements/styled";
import { Image } from "@theme-ui/components";
import { useUserContext } from "../../app/context/auth";
import styled from "@emotion/styled";
import format from "date-fns/format";

export default function EnquiriesTable({ viewEnquiry, dashboard }) {
  const user = useUserContext();

  const { enquiries } = useEnquiries();

  const enquiryList = useMemo(
    () =>
      enquiries
        .filter((u) => !u.isArchived)
        .filter((u) => u.createdBy === user?.id),
    [enquiries, user]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        id: "dateCreated",
        accessor: (d) => format(d?.dateCreated.toDate(), "YYY/MM/dd"),
        filter: "fuzzyText",
      },
      {
        Header: "Name",
        id: "name",
        accessor: (d) => `${d.firstName} ${d.lastName}`,
        filter: "fuzzyText",
      },
      {
        Header: "Phone Number",
        id: "phone",
        accessor: (d) => d.phone,
        filter: "fuzzyText",
      },
      {
        Header: "Email Address",
        id: "email",
        accessor: (d) => d.email,
        filter: "fuzzyText",
      },
      {
        Header: "Enquiry",
        id: "productRequired",
        accessor: (d) => d.productRequired,
        filter: "fuzzyText",
      },
    ],
    []
  );

  return (
    <TopContainer>
      <TableContainer>
        <ActionsTableContainer className="hide-on-mobile">
          <Table
            emptyMsg="No enquiries"
            columns={columns}
            data={enquiryList}
            defaultRows={5}
            emptyIcon="user-md"
          />
        </ActionsTableContainer>
      </TableContainer>
      <div className="hide-on-desktop" style={{ textAlign: "center" }}>
        <Image
          src="/assets/desktop.svg"
          style={{ width: "45%", margin: "auto" }}
        />
        <h4> Please go to the desktop version to view enquiries</h4>
      </div>
    </TopContainer>
  );
}

const TopContainer = styled.span`
  .hide-on-desktop {
    display: none !important;
  }
`;

const TableContainer = styled.span`
  .hide-on-desktop {
    display: none;
  }
  @media (max-width: 800px) {
    .hide-on-mobile {
      display: none;
    }
    .hide-on-desktop {
      display: block;
    }
  }
`;
