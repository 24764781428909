import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap, transformDocSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";
import { format, isBefore } from "date-fns";

const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
  const firebase = useFirebase();
  const [users, setUsers] = useState([]);
  const { claims } = useAuthContext();
  const [logins, setLogins] = useState([]);

  useEffect(() => {
    const unsubscribe = claims?.isAdmin
      ? firebase
          .users()
          .onSnapshot((snap) =>
            setUsers(transformCollectionSnap(snap).filter((p) => !p.isArchived))
          )
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, claims]);

  const createDate = (date) => {
    const sep1 = date.indexOf("-");
    const day = date.substring(0, sep1);
    const monthYear = date.substring(sep1 + 1);
    const sep2 = monthYear.indexOf("-");
    const month = monthYear.substring(0, sep2);
    const year = monthYear.substring(sep2 + 1);
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  };

  useEffect(() => {
    const getLogins = async () => {
      if (users.length > 0) {
        const loginSnap = await firebase.setting("brochureLogins").get();
        const logins = transformDocSnap(loginSnap);

        const dates = Object.entries(logins)
          .filter((i) => i[0] !== "id")
          .map((e) => {
            const date = createDate(e[0]);
            const userArr = e[1];
            const userNames = userArr?.map((i) => {
              const name = users.find((u) => u.id === i)?.displayName;
              return name;
            });
            return { date, users: userNames };
          })
          .sort((a, b) => {
            return isBefore(a.date, b.date) ? -1 : 1;
          })
          .map((i) => {
            return { ...i, date: format(i.date, "dd/MM/YYY") };
          });
        setLogins(dates);
      }
    };
    getLogins();
  }, [firebase, users]);

  return (
    <UsersContext.Provider value={{ users, logins }}>
      {children}
    </UsersContext.Provider>
  );
};

export const UsersConsumer = UsersContext.Consumer;

export const useUsers = () => useContext(UsersContext);
