import React from "react";
import { CSVLink } from "react-csv";
// import SimpleLoader from '../../../app/layout/SimpleLoader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@theme-ui/components";

//TODO: Duplicated

export default function DownloadReport({ data, headers, fileName, buttonTxt }) {
  const today = new Date();
  return (
    <CSVLink
      data={data}
      headers={headers}
      className="download-btn"
      filename={`reconise-${fileName}-${today}.csv`}
    >
      <Button>
        <FontAwesomeIcon icon={["fas", "download"]} />{" "}
        <span>{buttonTxt || "Download Report"}</span>
      </Button>
    </CSVLink>
  );
}
