import styled from "@emotion/styled";
import { primary } from "../../theme";

export const InputWrap = styled.div`
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: left;
  margin: 0.5em 0;
  input,
  div {
    border-color: ${(props) => {
      switch (props.state) {
        case "error":
          return props.theme.colors.warning.shade["300"];

        default:
          break;
      }
    }}!important;

    padding-left: ${(props) =>
      props.icon === "left" || props.icon === "both"
        ? " calc(2.5em + 1px)"
        : "auto"};

    padding-right: ${(props) =>
      props.icon === "right" || props.icon === "both"
        ? " calc(2.5em + 1px)"
        : "auto"};
  }
  svg {
    outline: 0;
    background-color: transparent !important;
  }

  .react-date-picker {
    height: 2.5em;
    max-width: 100%;
    width: 100%;
    .react-date-picker__wrapper {
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
      background-color: #fff;
      border-color: ${(props) => props.theme.colors.tusk.shade["500"]};
      border-radius: 2px;
      padding-bottom: calc(0.5em - 1px);
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-top: calc(0.5em - 1px);
      input {
        color: #363636;
      }
    }
  }
  abbr[title] {
    text-decoration: none;
  }
  .react-calendar {
    border-color: ${(props) => props.theme.colors.tusk.shade["500"]};
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    .react-calendar__tile--now {
      background: white;
      border: 2px dotted ${(props) => props.theme.colors[primary].shade["100"]};

      color: ${(props) => props.theme.colors[primary].shade["100"]};
    }
    .react-calendar__tile--active {
      background: ${(props) => props.theme.colors[primary].shade["100"]};
      color: ${(props) => props.theme.colors[primary].font["100"]}!important;
    }

    .react-calendar__tile {
      border-radius: 2px;
      color: black;
      &:disabled {
        border-radius: 0px;
        color: ${(props) => props.theme.colors.midnight.shade["100"]};
      }
    }
  }
  input.react-date-picker__inputGroup__input {
    outline: 0;
  }
  input.react-date-picker__inputGroup__input:invalid {
    background: white !important;
  }
`;

export const IconLeft = styled.span`
  left: 0;
  height: 2.5em;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  width: 2.5em;
  z-index: 4;
  display: grid;
  svg {
    align-self: center;
    justify-self: center;
  }
`;

export const IconRight = styled.span`
  right: 0;
  height: 2.5em;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  width: 2.5em;
  z-index: 4;
  padding: 2.5px;
  color: ${(props) => props.theme.colors[primary].shade["500"]};
  text-align: right;
  display: grid;
  svg {
    align-self: center;
    justify-self: center;
  }
`;
