export const transformCollectionSnap = (snap) => {
  return snap.docs.map((doc) => {
    const data = doc.data();

    return { id: doc.id, ...data };
  });
};

export const transformDocSnap = (snap) => {
  const data = snap.data();

  return { id: snap.id, ...data };
};

export const getObjFromLink = (link) => {
  const sep = link.indexOf("__");
  const id = link.substring(0, sep);
  const name = link.substring(sep + 2);
  return { id, name };
};

export const getExternalURL = (url) => {
  return url.includes("http") ? url : `http://${url}`;
};

export const scrollToSection = (section) => {
  var element = document.getElementById(section);
  var offset = 800;
  var sectionOffset = 80;
  var bodyRect = document.body.getBoundingClientRect().top;
  var elementRect = element.getBoundingClientRect().top;
  var elementPosition = elementRect - bodyRect;
  var offsetPosition = elementPosition - sectionOffset;

  section === "#about"
    ? window.scrollTo({
        top: offset,
        behavior: "smooth",
      })
    : window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
  // document.querySelector(section)?.scrollIntoView({
  //   behavior: "smooth",block:"start"
  // });
};
