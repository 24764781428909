

const button={
	cursor: "pointer",
	justifyContent: "center",
	paddingBottom: "calc(.7em - 1px)",
	paddingLeft: "1em",
	paddingRight: "1em",
	paddingTop: "calc(.7em - 1px)",
	textAlign: "center",
	whiteSpace: "nowrap",
	font: "inherit",
	"&:focus": {
		outline: 0
	},
	borderRadius:2
}

export default button
