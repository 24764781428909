import React, { useState } from "react";
import {
  Button,
  Radio,
  Label,
  Input,
  Heading,
  Divider,
} from "@theme-ui/components";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import styled from "@emotion/styled";
import Firebase from "../../../app/context/Firebase/functions";

const Brochures = ({ values, setValues }) => {
  // const ref = useRef();
  // const [docType, setDocType] = useState("link");
  const [loading, setLoading] = useState(false);

  const updateTitleChanged = (index) => (e) => {
    let newArr = [...values.brochures];
    newArr[index].title = e.target.value;
    setValues({ ...values, brochures: newArr });
  };

  const updateLinkChanged = (index) => (e) => {
    let newArr = [...values.brochures];
    newArr[index].pdfURL = e.target.value;
    setValues({ ...values, brochures: newArr });
  };

  const updateUploadChanged = (index) => (e) => {
    if (e.target.files[0]) {
      const document = e.target.files[0];
      let newArr = [...values.brochures];
      newArr[index].document = document;
      setValues({ ...values, brochures: newArr });
      // handleUpload(document);
    }
  };

  const updateNewLink = (index, url) => {
    let newArr = [...values.brochures];
    newArr[index].pdfURL = url;
    newArr[index].document = null;
    setValues({ ...values, brochures: newArr });
    setLoading(false);
  };

  const handleUpload = async (index, document) => {
    setLoading(true);
    const storageRef = Firebase.storage.ref();
    const ref = storageRef.child(`documents/${document.name}`);
    return await ref
      .put(document)
      .then(async () => updateNewLink(index, await ref.getDownloadURL()));
  };

  return (
    <BrochuresContainer>
      {values &&
        values.brochures &&
        values.brochures.map((brochure, index) => {
          return (
            <Container>
              <TopSection>
                <Heading as="h3">Document #{index + 1}</Heading>
                <Divider />
                <Label>Brochure type</Label>
                <div className="radiobtns">
                  <InputWrap>
                    <Label
                      variant="radioLabel"
                      style={{ textTransform: "none" }}
                    >
                      <Radio
                        checked={brochure.type === "link"}
                        name={`pdf${index}.type`}
                        value="link"
                        // value={brochure.type ? brochure.type : ""}
                        onChange={(e) => {
                          let newArr = [...values.brochures];
                          newArr[index].type = e.target.value;
                          setValues({ ...values, brochures: newArr });
                          // setDocType(e.target.value);
                        }}
                      />
                      Link
                    </Label>
                  </InputWrap>
                  <InputWrap>
                    <Label
                      variant="radioLabel"
                      style={{ textTransform: "none" }}
                    >
                      <Radio
                        checked={brochure.type === "upload"}
                        name={`pdf${index}.type`}
                        // value={brochure.type ? brochure.type : ""}
                        value="upload"
                        onChange={(e) => {
                          console.log(e.target.value);
                          let newArr = [...values.brochures];
                          newArr[index].type = e.target.value;
                          setValues({ ...values, brochures: newArr });
                          // setDocType(e.target.value);
                        }}
                      />
                      Upload
                    </Label>
                  </InputWrap>
                </div>
              </TopSection>
              <InputGrid key={index}>
                <InputWrap>
                  <Label>Title</Label>
                  <Input
                    name={`pdf${index}.title`}
                    value={brochure.title ? brochure.title : ""}
                    onChange={updateTitleChanged(index)}
                  ></Input>
                </InputWrap>
                {brochure.type === "link" ? (
                  <InputWrap>
                    <Label>PDF Link</Label>
                    <Input
                      name={`pdf${index}.pdfURL`}
                      value={brochure.pdfURL ? brochure.pdfURL : ""}
                      onChange={updateLinkChanged(index)}
                    ></Input>
                  </InputWrap>
                ) : (
                  <InputWrap>
                    <Label>PDF Upload</Label>
                    <Input
                      type="file"
                      name={`pdf${index}.pdfURL`}
                      // value={brochure.pdfURL ? brochure.pdfURL : ""}
                      onChange={updateUploadChanged(index)}
                    ></Input>
                  </InputWrap>
                )}
                <ButtonContainer>
                  {brochure.type === "upload" && (
                    <UploadButton
                      disabled={loading || !brochure.document}
                      onClick={() => {
                        !loading && brochure.document
                          ? handleUpload(index, brochure.document)
                          : console.log("loading");
                      }}
                    >
                      {loading ? "Loading" : "Upload"}
                    </UploadButton>
                  )}
                  <ClearButton
                    onClick={() => {
                      const brochuresArray = values.brochures;
                      brochuresArray.splice(index, 1);
                      setValues({
                        ...values,
                        brochures: brochuresArray,
                      });
                    }}
                  >
                    X
                  </ClearButton>
                </ButtonContainer>
              </InputGrid>
            </Container>
          );
        })}
    </BrochuresContainer>
  );
};

export default Brochures;

const Container = styled.div`
  display: grid;
  background-color: #f4f4f6;
  padding: 20px;
  margin-bottom: 5px;
`;

const TopSection = styled.div`
  .radiobtns {
    display: grid;
    grid-template-columns: 1fr 6fr;
  }
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 6fr 6fr 4fr;
  grid-gap: 20px;
`;

const ClearButton = styled(Button)`
  height: 2.5em;
  padding: 8px;
  width: 40px;
`;

const UploadButton = styled(Button)`
  height: 2.5em;
  padding: 8px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1em;
  align-items: center;
  margin-top: 28px;
`;

const BrochuresContainer = styled.div`
  margin-bottom: 20px;
`;
