export const regions = [
  {
    label: "Cape Town",
    value: "cape-town",
  },
  {
    label: "Free State",
    value: "free-state",
  },
  {
    label: "Gauteng",
    value: "gauteng",
  },
  {
    label: "KwaZulu-Natal",
    value: "kwazulu-natal",
  },
  {
    label: "Sub Saharan Africa",
    value: "sub-saharan-africa",
  },
  {
    label: "Namibia",
    value: "namibia",
  },
  {
    label: "Port Elizabeth",
    value: "port-elizabeth",
  },
  {
    label: "National",
    value: "national",
  },
];

export const _salesReps = [
  {
    region: "Free State",
    firstName: "Werner",
    lastName: "Griesel",
    title: "Regional Sls Manager",
    email: "werner.griesel@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Morne",
    lastName: "Odendaal",
    title: "Sls Executive",
    email: "MorneJohann.Odendaal@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Jean",
    lastName: "Strauss",
    title: "Sls Executive",
    email: "JeanJaeques.Strauss@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Willem",
    lastName: "Serfontein",
    title: "Sls Executive",
    email: "willem.serfontein@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Suzette",
    lastName: "Vorster",
    title: "Theatre Technician",
    email: "suzette.vorster@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Charl",
    lastName: "Coetzer",
    title: "Theatre Technician",
    email: "charl.coetzer@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Ancke",
    lastName: "Oelofse",
    title: "Theatre Technician",
    email: "ancke.oelofse@zimmerbiomet.com",
    scarfTie: "F",
  },

  {
    region: "Cape Town",
    firstName: "Craig",
    lastName: "Pepper",
    title: "Bus Dev Manager",
    email: "craig.pepper@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Chris",
    lastName: "Regel",
    title: "Key Account Manager",
    email: "chris.regel@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Uluande",
    lastName: "Marais",
    title: "Sls Executive",
    email: "Urscheluande.Marais@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Donald",
    lastName: "Froneman",
    title: "Sls Executive",
    email: "donald.froneman@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Le Roux",
    lastName: "Van Aarde",
    title: "Sls Executive",
    email: "le roux.van aarde@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Nicholas",
    lastName: "Da Silva",
    title: "Sls Executive",
    email: "nicholas.da silva@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Alastair",
    lastName: "Laurence",
    title: "Sls Executive",
    email: "alastair.laurence@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Nathan",
    lastName: "Johnston",
    title: "Sls Executive",
    email: "NathanDale.Johnston@zimmerbiomet.com",
    scarfTie: "M",
  },

  {
    region: "Kwa-Zulu Natal",
    firstName: "Chris",
    lastName: "Venter",
    title: "Regional Sls Manager",
    email: "chris.venter@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Ryan",
    lastName: "Mash",
    title: "Key Account Manager",
    email: "ryan.mash@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Mark",
    lastName: "Sivell",
    title: "Sls Executive",
    email: "mark.sivell@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Hollinshead",
    lastName: "Matthew",
    title: "Sls Executive",
    email: "Matthew.Hollinshead@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "John",
    lastName: "Haskins",
    title: "Sls Executive",
    email: "john.haskins@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Emily",
    lastName: "Freestone-Harris",
    title: "Sls Executive",
    email: "Emily.freestoneharris@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Graeme",
    lastName: "Brebner",
    title: "Sls Executive",
    email: "Graeme.Brebner@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Larry",
    lastName: "Oosthuyzen",
    title: "Theatre Technician",
    email: "larry.oosthuyzen@zimmerbiomet.com",
    scarfTie: "M",
  },

  {
    region: "Gauteng - North",
    firstName: "Shaun",
    lastName: "Whittal",
    title: "Regional Sls Manager",
    email: "shaun.whittal@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Hein",
    lastName: "De Klerk",
    title: "Key Account Manager",
    email: "hein.de klerk@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Chrisna",
    lastName: "Harmse",
    title: "Key Account Manager",
    email: "crisna.harmse@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Catherine",
    lastName: "Smith",
    title: "Sls Executive",
    email: "catherine.smith@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Swanepoel",
    lastName: "Rudi",
    title: "Sls Executive",
    email: "Rudie.Swanepoel@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Anke",
    lastName: "Dedekind",
    title: "Sls Executive",
    email: "anke.dedekind@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Craig",
    lastName: "Allchurch",
    title: "Sls Executive",
    email: "craig.allchurch@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Alexia",
    lastName: "Lombard",
    title: "Theatre Technician",
    email: "alexia.lombard@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Thulani",
    lastName: "Ndlovu",
    title: "Theatre Technician",
    email: "Thulani.Ndlovu@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Mariessa",
    lastName: "Breed",
    title: "Theatre Technician",
    email: "mariessa.breed@zimmerbiomet.com",
    scarfTie: "F",
  },

  {
    region: "Gauteng - South 1",
    firstName: "Brandon",
    lastName: "Le Roux",
    title: "Regional Sls Manager",
    email: "brandon.leroux@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Brendon",
    lastName: "Wecke",
    title: "Key Account Manager",
    email: "brendon.wecke@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Rudi",
    lastName: "Rademeyer",
    title: "Sls Executive",
    email: "rudi.rademeyer@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Gavin",
    lastName: "Manyelo",
    title: "Sls Executive",
    email: "gavin.manyelo@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Shanene",
    lastName: "Beyers",
    title: "Sls Executive",
    email: "shanene.beyers@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Jacques",
    lastName: "Smith",
    title: "Sls Executive",
    email: "jacques.smith@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Byron",
    lastName: "Labuschagne",
    title: "Sls Executive",
    email: "byron.labuschagne@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "William",
    lastName: "Mkhatshwa",
    title: "Sls Executive",
    email: "william.mkhatshwa@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Christopher",
    lastName: "Zithwana",
    title: "Theatre Technician",
    email: "ChristopherPetros.Zithwana@zimmerbiomet.com",
    scarfTie: "M",
  },

  {
    region: "Gauteng - South 2",
    firstName: "Morne",
    lastName: "Van der Merwe",
    title: "Sls Team Leader",
    email: "morne.merwe@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Hilda",
    lastName: "Van Der Bank",
    title: "Key Account Manager",
    email: "hilda.vanderbank@zimmerbiomet.com",
    scarfTie: "F",
  },
  {
    region: "",
    firstName: "Lionel",
    lastName: "Kraayeveld",
    title: "Sls Executive",
    email: "lionel.kraayeveld@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Ricardo",
    lastName: "Louw",
    title: "Sls Executive",
    email: "ricardo.louw@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Neil",
    lastName: "Lourens",
    title: "Theatre Technician",
    email: "neil.lourens@zimmerbiomet.com",
    scarfTie: "M",
  },

  {
    region: "Sub-Saharan Africa",
    firstName: "Jaco",
    lastName: "Erasmus",
    title: "Bus Dev Manager",
    email: "jaco.erasmus@zimmerbiomet.com",
    scarfTie: "",
  },
  {
    region: "",
    firstName: "Zwelethu",
    lastName: "Mbizane",
    title: "Sls Executive",
    email: "zwelethu.mbizane@zimmerbiomet.com",
    scarfTie: "",
  },
  {
    region: "",
    firstName: "Jabulane",
    lastName: "Mkhasibe",
    title: "Sls Executive",
    email: "jabulane.mkhasibe@zimmerbiomet.com",
    scarfTie: "",
  },

  {
    region: "Spine",
    firstName: "Johan",
    lastName: "Kilian",
    title: "Sls Executive",
    email: "johan.kilian@zimmerbiomet.com",
    scarfTie: "",
  },
  {
    region: "",
    firstName: "Warren",
    lastName: "Forrester-Jones",
    title: "Sls Executive",
    email: "Warren.ForresterJones@zimmerbiomet.com",
    scarfTie: "",
  },

  {
    region: "Management",
    firstName: "Philip",
    lastName: "Heyns",
    title: "Strategic Account Manager",
    email: "philip.heyns1@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Warren",
    lastName: "Lottering",
    title: "Product Sr Manager",
    email: "warren.lottering@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Roche",
    lastName: "Swiegers",
    title: "National Sls Manager",
    email: "roche.swiegers@zimmerbiomet.com",
    scarfTie: "M",
  },
  {
    region: "",
    firstName: "Werner",
    lastName: "Weeber",
    title: "National Sls Manager",
    email: "werner.weeber@zimmerbiomet.com",
    scarfTie: "M",
  },
];
