import React from "react";
import ProductForm from "./ProductForm";
import { Card } from "@theme-ui/components";
import { useToasts } from "react-toast-notifications";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { useFirebase } from "../../../app/context/Firebase";

export default function EditProduct({ selectedProduct, setView }) {
  const { addToast } = useToasts();
  const firebase = useFirebase();

  const submit = (values) => {
    firebase.updateProduct(values).then(() => {
      addToast("Product Updated", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      setView("view");
    });
  };

  const archiveProduct = () => {
    firebase.archiveProduct(selectedProduct.id).then(() => {
      addToast("Product Archived", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      setView("view");
    });
  };
  return (
    <>
      <Card>
        <BlockHeaderContainer
          title="Edit Product"
          actionType="button"
          action={() => setView("view")}
          buttonText="Back to catalogue"
        />
        <ProductForm
          archive={() => archiveProduct()}
          product={selectedProduct}
          buttonText="Update"
          submit={submit}
        />
      </Card>
    </>
  );
}
