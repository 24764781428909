import React, { useState, useEffect } from "react";
import { Card, AspectRatio } from "@theme-ui/components";
import { ImageBlock } from "../../app/DSL/components/elements/styled";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../app/context/Firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToasts } from "react-toast-notifications";
import { useUserContext } from "../../app/context/auth";

export default function ProductCard({ product }) {
  var history = useHistory();
  const firebase = useFirebase();
  const { addToast } = useToasts();
  const [isFeatured, setIsFeatured] = useState(false);

  const user = useUserContext();

  useEffect(() => {
    const featuredProduct =
      user?.brochureFeaturedList &&
      user.brochureFeaturedList.filter((u) => u === product?.id);
    if (featuredProduct && featuredProduct[0] === product?.id) {
      setIsFeatured(true);
    } else {
      setIsFeatured(false);
    }
  }, [product, user]);

  var openProduct = () => {
    firebase.viewProduct(product.id).then(() => {
      history.push(`/catalogue/${product.id}`);
    });
  };

  var featureProduct = () => {
    if (user && product) {
      if (!isFeatured) {
        const featuredArray = user?.brochureFeaturedList
          ? user.brochureFeaturedList
          : [];
        featuredArray.push(product.id);

        firebase.featureProduct(user.id, featuredArray).then(() => {
          addToast("Product Featured", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 1500,
          });
        });
      } else {
        const featuredArray = user?.brochureFeaturedList
          ? user.brochureFeaturedList
          : [];
        const index = featuredArray.indexOf(product.id);
        featuredArray.splice(index, 1);

        firebase.featureProduct(user.id, featuredArray).then(() => {
          addToast("Feature Removed", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 1500,
          });
        });
      }
    }
  };

  return (
    <ProductContainer
      variant="small-hoverable"
      style={{ textAlign: "center", height: "100%" }}
    >
      <div onClick={openProduct} className="content">
        <AspectRatio ratio={1 / 1}>
          <img
            src={
              product && product.photoURL
                ? product.photoURL
                : "/user-circle-solid.svg"
            }
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </AspectRatio>
        <p>{product.title}</p>
      </div>
      <BottomBox>
        {isFeatured ? (
          <FontAwesomeIcon
            className="featured"
            icon={["fas", "star"]}
            onClick={featureProduct}
          />
        ) : (
          <FontAwesomeIcon
            className="notFeatured"
            icon={["fal", "star"]}
            onClick={featureProduct}
          />
        )}
      </BottomBox>
      <BottomBorder />
      <GreyBox />
    </ProductContainer>
  );
}

const ProductContainer = styled(Card)`
  position: relative;
  p {
    margin-top: 10px;
    font-weight: bold;
    color: #58595b;
  }
  .content {
    position: relative;
    z-index: 5;
  }
`;

const BottomBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  * {
    color: #0081c9 !important;
    transition: all 0, 2s ease;
  }
  *:hover {
    color: #006ca7 !important;
  }
`;

const BottomBorder = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 5px;
  z-index: 10;
  background-image: url("/assets/bg.png");
  background-size: 100%;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
`;

const GreyBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 200px;
  background-color: ${(props) => props.theme.colors.tusk.shade["200"]};
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  z-index: 1;
`;
