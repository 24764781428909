import React from "react";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Image, Button } from "@theme-ui/components";
import { useAuthContext } from "../../app/context/auth";
import { Redirect } from "react-router-dom";

export default function NoAccess() {
  const { allowed } = useAuthContext();

  if (allowed) {
    return <Redirect to="dashboard" />;
  }
  return (
    <div>
      <PageHeaderContainer>Access denied</PageHeaderContainer>
      <Card style={{ textAlign: "center" }}>
        <Image src="/assets/no-access.svg" width="20%" />
        <h3>You do not have access to this platform.</h3>
        <p>
          Please contact an admin to request access if this is not expected.
        </p>
        <Button as="a" href={process.env.REACT_APP_HQ_LINK + "/request-access"}>
          Request Access
        </Button>
      </Card>
    </div>
  );
}
