import React from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { Card, Image } from "@theme-ui/components";
import EnquiriesTable from "./EnquiriesTable";
import FeaturedProducts from "./FeaturedProducts";
import { useHistory } from "react-router-dom";
import Slider from "./Slider";

const Dashboard = () => {
  var history = useHistory();
  return (
    <div>
      <PageHeaderContainer image>
        <Image
          src={`/assets/${
            process.env.REACT_APP_LOGO
              ? process.env.REACT_APP_LOGO
              : "long-logo.svg"
          }`}
          width="520px"
        />
      </PageHeaderContainer>
      <Card>
        <Slider />
      </Card>
      <TopGrid>
        <Card>
          <BlockHeaderContainer title="My Favourites" />
          <FeaturedProducts />
        </Card>
        <Card>
          {/* <BlockHeaderContainer title='Recent Enquiries' /> */}
          {/* TODO: change to actionType link? */}
          <BlockHeaderContainer
            title="Recent Enquiries"
            actionType="button"
            action={() => history.push("/enquiries")}
            buttonText="View All"
          />
          <EnquiriesTable dashboard={true} />
        </Card>
      </TopGrid>
    </div>
  );
};

export default Dashboard;

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 1em;
  margin-top: 1em;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
