import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";
//import { formatEnquiry } from "./formatting";

//TODO: create, update, archive enquiry move here

const EnquiriesContext = createContext({});

export const EnquiriesProvider = ({ children }) => {
  const firebase = useFirebase();
  const [enquiries, setEnquiries] = useState([]);
  const { user } = useAuthContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .enquiries()
          .onSnapshot((snap) =>
            setEnquiries(
              transformCollectionSnap(snap).filter((p) => !p.isArchived)
            )
          )
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  return (
    <EnquiriesContext.Provider value={{ enquiries }}>
      {children}
    </EnquiriesContext.Provider>
  );
};

export const EnquiriesConsumer = EnquiriesContext.Consumer;

export const useEnquiries = () => useContext(EnquiriesContext);
