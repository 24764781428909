import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Card, Image, Heading, Divider } from "@theme-ui/components";
import { ImageBlock } from "../../app/DSL/components/elements/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { useMemo } from "react";
import { useProducts } from "../../app/context/catalogue";
import { StyledLabel } from "../../app/DSL/components/elements/styled";
import { primary } from "../../app/DSL/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getExternalURL, scrollToSection } from "../../app/context/helpers";
import { Link, NavLink } from "react-router-dom";
import ReactModal from "react-modal";

export default function ProductDetails({ match }) {
  const [videoOpen, setVideoOpen] = useState(false);
  const { products } = useProducts();
  const product = useMemo(
    () => products.find((u) => u.id === match?.params?.id),
    [match, products]
  );

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://players.brightcove.net/6153537080001/E6YPJvPVJ_default/index.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openVideo = () => {
    setVideoOpen(true);
  };

  return (
    <div>
      <PageHeaderContainer>View Product</PageHeaderContainer>
      <HeaderCard>
        <div className="top">
          <div className="categories">
            {product?.category && (
              <StyledLabel colour="blue">{product.category}</StyledLabel>
            )}
            {product?.type && (
              <StyledLabel colour="darkBlue">{product.type}</StyledLabel>
            )}
            {product?.brand && (
              <StyledLabel colour="teal">{product.brand}</StyledLabel>
            )}
          </div>
          <Heading as="h1">{product?.title}</Heading>
        </div>

        <div
          className="bottom"
          onClick={() =>
            product?.videoURL || product?.videoURLBrightCove
              ? openVideo()
              : null
          }
        >
          {product?.videoURL || product?.videoURLBrightCove ? (
            <>
              <Card className="play-btn" variant="hoverable">
                <FontAwesomeIcon icon={["fas", "play"]} />
              </Card>
              <p>Watch product video</p>
            </>
          ) : null}
        </div>
        <div className="right">
          <img src={product.photoURL} alt="" />
        </div>
      </HeaderCard>
      <ContentCard>
        <div>
          <h3>Overview</h3>
          <p> {product.description} </p>
        </div>
        <div>
          {product?.videoURL || product?.videoURLBrightCove ? (
            <div id="video">
              <h3>Video</h3>
              {product?.videoURL ? (
                <VideoContainer>
                  <div className="video-container">
                    <iframe
                      title="video"
                      src={product.videoURL}
                      frameBorder="0"
                      className="video"
                      allowFullScreen
                    />
                  </div>
                </VideoContainer>
              ) : (
                //TODO: refactor brightcove into component
                product?.videoURLBrightCove && (
                  <div>
                    <video-js
                      data-account="6153537080001"
                      data-player="E6YPJvPVJ"
                      data-embed="default"
                      controls=""
                      data-video-id="6155219228001"
                      data-playlist-id=""
                      data-application-id=""
                      class="vjs-fluid"
                    ></video-js>
                  </div>
                )
              )}
            </div>
          ) : null}
        </div>
        {product?.brochures?.length > 0 ? (
          <div>
            <h3>PDF</h3>
            <BrochureGrid>
              {product?.brochures?.map((brochure, idx) => (
                <a
                  href={getExternalURL(brochure.pdfURL)}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={idx}
                >
                  <Document variant="hoverable">
                    <FontAwesomeIcon icon={["fas", "file-pdf"]} size="2x" />
                    <p>{brochure.title}</p>
                    <FontAwesomeIcon icon={["fal", "download"]} size="lg" />
                  </Document>
                </a>
              ))}
            </BrochureGrid>
          </div>
        ) : null}
        <ReactModal
          isOpen={videoOpen}
          onRequestClose={() => setVideoOpen(false)}
          overlayClassName="overlay"
          className="content"
        >
          {product?.videoURL ? (
            <VideoContainer>
              <div className="video-container">
                <iframe
                  title="video"
                  src={product.videoURL}
                  frameBorder="0"
                  className="video"
                  allowFullScreen
                />
              </div>
            </VideoContainer>
          ) : (
            //TODO: refactor brightcove into component
            product?.videoURLBrightCove && (
              <div>
                <video-js
                  data-account="6153537080001"
                  data-player="E6YPJvPVJ"
                  data-embed="default"
                  controls=""
                  data-video-id="6155219228001"
                  data-playlist-id=""
                  data-application-id=""
                  class="vjs-fluid"
                ></video-js>
              </div>
            )
          )}
        </ReactModal>
      </ContentCard>
    </div>
  );
}
const HeaderCard = styled(Card)`
  margin-bottom: 1em;
  padding: 0;
  position: relative;
  .top {
    background-image: url("/assets/bg.png");
    padding: 5em;
    background-size: 100%;
    display: flex;
    justify-items: center;
    flex-direction: column;
    @media (max-width: 1024px) {
      padding: 2em;
    }
    .categories {
      display: flex;
      justify-items: center;
      > div {
        margin: 0.5em;
        width: fit-content;
      }
      > div:first-child {
        margin-left: 0;
      }
    }

    * {
      color: white;
      font-weight: normal;
    }

    h1 {
      font-size: 4em;
      width: 60%;
      @media (max-width: 1024px) {
        width: 50%;
      }
    }
  }

  .bottom {
    padding-left: 5em;
    height: 70px;
    display: flex;
    align-items: center;
    p {
      margin-left: 1em;
      font-size: 1.1em;
      font-weight: 300;
    }
    .play-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 0;
      color: ${({ theme: { colors } }) => colors[primary].shade[900]}!important;
    }
  }
  .right {
    position: absolute;
    right: 5em;
    top: 0;
    bottom: 0;
    left: 50%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      height: 350px;
    }
    @media (max-width: 1024px) {
      right: 2em;
    }
  }
`;
const ContentCard = styled(Card)`
  padding: 1px 5em 2em;
  h3 {
    font-size: 1.8em;
    color: ${({ theme: { colors } }) => colors[primary].shade[900]}!important;
    font-weight: 400;
  }
  p {
    font-size: 1.2em;
  }
  .content {
    width: 100%;
  }
  @media (max-width: 1024px) {
    padding: 1px 2em 2em;
  }
`;

const BrochureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Document = styled(Card)`
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1em;
  align-items: center;
  svg:first-child {
    color: ${(props) => props.theme.colors[primary].shade[500]};
  }
  * {
    margin: 0;
  }
`;

const MessageCard = styled(Card)`
  padding: 1em 2em;
  margin-bottom: 1em;
  background: ${(props) => props.theme.colors[primary].shade[700]};
  color: ${(props) => props.theme.colors[primary].font[700]};
`;
