import React from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { Card } from "@theme-ui/components";
// import EventsList from "./EventsList";
import RecentLogins from "./RecentLogins";
import InactiveUsers from "./InactiveUsers";
import styled from "@emotion/styled";
import DownloadReport from "../Enquiries/DownloadReport";
import { useUsers } from "../../../app/context/users";

export default function UserActivity() {
  const { logins } = useUsers();
  return (
    <div>
      <PageHeaderContainer>User Activity</PageHeaderContainer>
      <TopGrid>
        <div>
          <Card style={{ marginBottom: "1em" }}>
            <BlockHeaderContainer title="Login Reports" />
            <DownloadReport
              fileName="logins"
              data={logins}
              headers={[
                { label: "Date", key: "date" },
                { label: "Users", key: "users" },
              ]}
            />
          </Card>
          <Card>
            <BlockHeaderContainer title="Recent Logins" />
            <RecentLogins />
          </Card>
        </div>
        <Card>
          <BlockHeaderContainer title="Users still to Log in" />
          <InactiveUsers />
        </Card>
      </TopGrid>
    </div>
  );
}

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
