import React from "react";
import styled from "@emotion/styled";
import { useProducts } from "../../../app/context/catalogue";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../../app/context/Firebase";
import { Card, Divider } from "@theme-ui/components";

export default function MostViewedProducts() {
  const { products } = useProducts();
  var history = useHistory();
  const firebase = useFirebase();

  const catalogue = useMemo(
    () =>
      products
        ? products
            .filter((p) => !p.isArchived)
            .filter((p) => p && p.viewCount && p.viewCount !== 0)
            .sort((a, b) => (a.viewCount > b.viewCount ? 1 : -1))
            .sort((a, b) => b.viewCount - a.viewCount)
            .reverse()
            .slice(0, 5)
        : [],
    [products]
  );

  var openProduct = (id) => {
    firebase.viewProduct(id).then(() => {
      history.push(`/catalogue/${id}`);
    });
  };

  return (
    <div>
      {catalogue.map((p, idx) => (
        <ProductItem
          key={idx}
          onClick={() => openProduct(p.id)}
          style={{ textAlign: "center", height: "100%" }}
          variant='hoverable'
        >
          {p.title}
          <Divider />
          <div className='productFooter'>
            <span></span>
            <span>{p.viewCount > 0 ? p.viewCount : 0} views</span>
          </div>
        </ProductItem>
      ))}
    </div>
  );
}

const ProductItem = styled(Card)`
  text-align: left !important;
  margin-bottom: 10px;
  padding: 1em 1em 0.5em 1em !important;
  border: 1px solid #e9e9ed;
  border-bottom: 2px solid #0081c9;
  position: relative;
  .productFooter {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    span {
      font-size: 12px;
      color: #999;
    }
    span:nth-child(2) {
      text-align: right;
    }
  }
  &:hover {
    cursor: pointer;
  }
`;
